<template>
  <div>
    <v-list-item class="mb-0 ml-n2 justify-space-between pl-3" v-if="defaultLogo">
      <a href="https://www.softclient.com.au/">
        <v-list-item-avatar>
          <v-img :src="require('@/assets/sclogo.jpg')" />
        </v-list-item-avatar>
      </a>
      <v-list-item-content class="pl-2">
        <v-list-item-title class="text-h7">
          <strong class="mr-1 font-weight-black">mysoftclient</strong>

          <span class="primary--text">POS</span>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <div class="d-flex justify-center px-2 my-4" v-else>
      <v-img :src="logoImage" width="80" @error="logoImageLoadError" />
    </div>
  </div>
</template>

<script>
// Utilities
import { get } from "vuex-pathify";

export default {
  name: "DefaultDrawerHeader",
  data: () => ({
    defaultLogo: true,
  }),
  computed: {
    version: get("app/version"),
    logoImage() {
      return (
        "https://filesstorage.azurewebsites.net/img/" +
        this.currentHost +
        "-logo.png"
      );
    },
    currentHost() {
      return window.location.host;
    },
  },
  methods: {
    logoImageLoadError() {
      this.defaultLogo = true;
    },
  },
};
</script>
